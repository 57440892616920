import { useState } from 'react';
import SidebarOrganism from 'app/components/organisms/sidebar';
import EristaLogoLandscape from 'img/erista-logo-landscape.png';
import EristaLogo from 'img/erista-logo.png';
import SLLogo from "img/sl-logo.PNG";
import SLLogoLandscape from "img/sl-logo-landscape.png";
import {
  HomeIcon,
  ShoppingCartIcon,
  Cog6ToothIcon,
  IdentificationIcon,
  TableCellsIcon,
  UsersIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/24/outline";

const navigations = [
  // {
  //   id: 'dashboard',
  //   label: 'Dashboard',
  //   icon: HomeIcon,
  //   href: '/dashboard',
  // },
  {
    id: "customer",
    label: "Customer",
    icon: IdentificationIcon,
    href: "/customer",
    // children: [
    //   {
    //     id: 'customer',
    //     label: 'Customer',
    //     href: '/customer',
    //   },
    //   {
    //     id: 'credit-history',
    //     label: 'Credit History',
    //     href: '/credit_history',
    //   },
    // ],
  },
  {
    id: "order",
    label: "Sales Order",
    icon: ShoppingCartIcon,
    href: "/sales_order",
  },
  {
    id: "invoice",
    label: "Sales Invoice",
    icon: CurrencyDollarIcon,
    href: "/invoice",
  },
  {
    id: "report",
    label: "Report",
    icon: TableCellsIcon,
    children: [
      {
        id: "daily-order",
        label: "Order Harian",
        href: "/report/daily_order",
      },
      {
        id: "daily-menu-report",
        label: "Menu Harian",
        href: "/report/daily_menu",
      },
      {
        id: "payment",
        label: "Pembayaran",
        href: "/report/payment",
      },
      {
        id: "credit-recap",
        label: "Kredit Rekap",
        href: "/report/credit_recap",
      },
      {
        id: "credit-history",
        label: "Kredit Histori",
        href: "/report/credit_history",
      },
    ],
  },
  {
    id: "settings",
    label: "Settings",
    icon: Cog6ToothIcon,
    children: [
      {
        id: "daily-menu",
        label: "Menu Harian",
        href: "/daily_menu",
      },
      {
        id: "variant",
        label: "Kategori Menu",
        href: "/variant",
      },
      {
        id: "menu-category",
        label: "Jumlah Menu",
        href: "/menu_category",
      },
      {
        id: "shipment-cost-category",
        label: "Kategori Ongkir",
        href: "/shipment_cost_category",
      },
    ],
  },
  // {
  //   id: "auth",
  //   label: "User",
  //   icon: UsersIcon,
  //   children: [
  //     {
  //       id: "user",
  //       label: "Daftar User",
  //       href: "/user",
  //     },
  //     {
  //       id: "role",
  //       label: "Roles & Permission",
  //       href: "/role",
  //     },
  //   ],
  // },
];

function AgnosticSidebar({ activeNavigation, activeChild }) {
  const sidebarLogo = {
    image: SLLogoLandscape,
    collapsedImage: SLLogo,
    imageAlt: "SL Kitchen Logo",
    collapsedImageAlt: "SL Kitchen Logo",
  };

  const calculateNavigations = (navigations) => {
    const calculatedNavigations = navigations.map((navigation) => {
      const active = navigation.id === activeNavigation;
      navigation.active = active;

      if (navigation.children) {
        navigation.expanded = active;
        navigation.children = navigation.children.map((child) => {
          child.active = child.id === activeChild;
          return child;
        });
      }

      return navigation;
    });
    return calculatedNavigations;
  };

  const [calculatedNavigations, setCalculatedNavigations] = useState(
    calculateNavigations(navigations)
  );

  const handleOnChange = (newCalculatedNavigations) => {
    setCalculatedNavigations(newCalculatedNavigations);
  };

  return (
    <SidebarOrganism
      sidebarLogo={sidebarLogo}
      navigations={calculatedNavigations}
      onChange={handleOnChange}
    />
  );
}

export default AgnosticSidebar;
