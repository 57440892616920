
function TableHeader({ children }) {
  return (
    <thead className="bg-gray-100 dark:bg-gray-700 bg-opacity-75 print:table-header-group">
      {children}
    </thead>
  );
}

export default TableHeader;
