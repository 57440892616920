import { useApplicationModals } from "app/common/hooks";
import { useCreateForm } from "app/integration/common/hooks";
import * as Yup from "yup";
import api from "app/integration/api";
import { ModalMolecule } from "app/components/molecules/modal";
import { Formik } from "formik";
import { InputMolecule } from "app/components/molecules/form";
import { Button } from "app/components/atoms/button";
import { SelectMolecule, SelectSearchMolecule } from "app/components/molecules/select";
import { Select } from "app/components/atoms/select";
import { DateTime } from "luxon";
import AppModals from ".";

function CreateCreditModal({ onSuccess = () => {} }) {
  const { showStates, closeModal, getPayloads } = useApplicationModals();
  const payloads = getPayloads("credit.create");

  const handleClose = () => closeModal("credit.create");
  const handleSuccess = () => {
    closeModal("credit.create");
    onSuccess();
  };

  const now = DateTime.now();
  const dt = now.toISODate();
  const time = now.toLocaleString(DateTime.TIME_24_SIMPLE);

  var isError = false;
  const { openModal, setPayloads } = useApplicationModals();

  const { initialValues, validationSchema, onSubmit } = useCreateForm({
    initialValues: {
      customerId: "",
      method: 0,
      refNumber: "",
      bankName: "",
      accountNumber: "",
      amount: 0,
      desc: "",
      creditType: 1,
    },
    validationSchema: Yup.object().shape({
      customerId: Yup.string().required("Customer harus dipilih"),
      method: Yup.string().required("Tambah / Kurang Kredit harus dipilih"),
      refNumber: Yup.string().when("method", {
        is: (method) => method?.toUpperCase() === "BANK",
        then: Yup.string(),
      }),
      bankName: Yup.string().when("method", {
        is: (method) => method?.toUpperCase() === "BANK",
        then: Yup.string(),
      }),
      accountNumber: Yup.string().when("method", {
        is: (method) => method?.toUpperCase() === "BANK",
        then: Yup.string(),
      }),
      amount: Yup.number()
        .required("Jumlah Kredit harus diisi")
        .min(1, "Jumlah Kredit harus lebih dari 0."),
      desc: Yup.string(),
      creditType: Yup.string(),
    }),
    onSubmit: async (values) => {
      await api.credit
        .store(
          values.customerId,
          values.method,
          values.refNumber,
          values.bankName,
          values.accountNumber,
          values.amount,
          values.desc,
          values.creditType
        )
        .catch((error) => {
          // console.log("eror: ", error);
          setPayloads("errorMessage.view", {
            title: "Tambah / Kurang Kredit Error",
            message:
              error?.response.status === 400
                ? "Gagal Ubah Kredit. " + error?.response?.data?.message
                : error?.response?.data?.message,
          });
          isError = true;
          openModal("errorMessage.view");
        });
    },
    onSuccess: () => {
      if (!isError) {
        handleSuccess();
      }
    },
  });

  return (
    <ModalMolecule
      show={showStates["credit.create"]}
      title="Tambah / Kurang Kredit"
      onClose={handleClose}
    >
      <AppModals
        items={["errorMessage.view"]}
        onSuccess={{
          "errorMessage.view": () => fetch(),
        }}
      />
      <Formik
        {...{ initialValues, validationSchema, onSubmit }}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form className="space-y-4" onSubmit={handleSubmit}>
            <SelectMolecule
              placeholder="Pilih salah satu"
              name="creditType"
              label="Tambah / Kurang Kredit"
              value={values.creditType}
              onChange={handleChange}
              options={[
                {
                  value: 1,
                  label: "Tambah Kredit",
                },
                {
                  value: 2,
                  label: "Kurang Kredit",
                },
              ]}
            />
            <SelectSearchMolecule
              label="Customer"
              name="customerId"
              options={
                payloads?.customers?.length > 0 &&
                payloads?.customers?.map((customer) => ({
                  value: customer.id,
                  label:
                    customer.first_name +
                    " " +
                    customer.middle_name +
                    " " +
                    customer.last_name +
                    (customer.nick_name ? " (" + customer.nick_name + ")" : ""),
                }))
              }
              onChange={(e) => {
                console.log("value: ", e.target.value);
                handleChange(e);
              }}
              onBlur={handleBlur}
              value={values.customerId}
              errorMessage={
                errors.customerId && touched.customerId && errors.customerId
              }
            />
            <InputMolecule
              label="Jumlah Kredit"
              type="number"
              name="amount"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.amount}
              errorMessage={errors.amount && touched.amount && errors.amount}
            />
            <InputMolecule
              label="Catatan"
              type="text"
              name="desc"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.desc}
              errorMessage={errors.desc && touched.desc && errors.desc}
            />
            <div className="grid grid-cols-2 gap-2">
              <SelectMolecule
                placeholder="Pilih salah satu"
                name="method"
                label="Metode Pembayaran"
                value={values.method}
                onChange={handleChange}
                options={[
                  {
                    value: 1,
                    label: "Bank Transfer",
                  },
                  {
                    value: 2,
                    label: "E-Wallet",
                  },
                  {
                    value: 3,
                    label: "Tunai",
                  },
                ]}
              />
              <InputMolecule
                label="Nomor Referensi"
                type="text"
                name="refNumber"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.refNumber}
                errorMessage={
                  errors.refNumber && touched.refNumber && errors.refNumber
                }
              />
              <InputMolecule
                label="Nama Bank"
                type="text"
                name="bankName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.bankName}
                errorMessage={
                  errors.bankName && touched.bankName && errors.bankName
                }
              />
              <InputMolecule
                label="No. Account"
                type="text"
                name="accountNumber"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.accountNumber}
                errorMessage={
                  errors.accountNumber &&
                  touched.accountNumber &&
                  errors.accountNumber
                }
              />
            </div>
            <div></div>
            <div className="fixed bottom-2 left-0 sm:left-auto right-0 sm:right-4 sm:bottom-4 sm:w-auto z-40 flex justify-end border-t border-gray-100 bg-white sm:bg-transparent p-4 sm:p-0 dark:border-gray-700 dark:bg-gray-800 print:hidden sm:absolute sm:rounded-b-lg sm:border-0 ">
              <Button type="submit">Register Kredit</Button>
            </div>
          </form>
        )}
      </Formik>
    </ModalMolecule>
  );
}

export default CreateCreditModal;
