import { useApplicationModals } from "app/common/hooks";
import { Button } from "app/components/atoms/button";
import { InputLabel } from "app/components/atoms/form";
import Typography from "app/components/atoms/typography";
import { InputMolecule } from "app/components/molecules/form";
import { ModalMolecule } from "app/components/molecules/modal";
import { SelectMolecule } from "app/components/molecules/select";
import api from "app/integration/api";
import { useCreateForm, useFetcher } from "app/integration/common/hooks";
import { Formik } from "formik";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import AppModals from ".";

function CreatePaymentModal({ onSuccess = () => {} }) {
  const [isLoading, setIsLoading] = useState(false);
  const { showStates, closeModal, getPayloads, openModal, setPayloads } =
    useApplicationModals();
  const payloads = getPayloads("payment.create");

  const [paymentStatus, setPaymentStatus] = useState("");
  const [paymentAmount, setPaymentAmount] = useState(0);

  const handleClose = () => closeModal("payment.create");
  const handleSuccess = () => {
    closeModal("payment.create");
    onSuccess();
  };

  const now = DateTime.now();
  const dt = now.toISODate();
  const time = now.toLocaleString(DateTime.TIME_24_SIMPLE);

  const [invoice, setInvoice, loader, fetch] = useFetcher({
    modelName: "invoice",
    payload: { id: payloads.invoiceId },
  });

  var isError = false;

  const { initialValues, validationSchema, onSubmit } = useCreateForm({
    initialValues: {
      invoiceId: payloads.invoiceId,
      paymentDate: dt,
      paymentTime: time,
      method: 1,
      refNumber: "",
      bankName: "",
      accountNumber: "",
      totalPayment: 0,
      status: false,
      paymentSource: 1,
      mode: 1,
    },
    validationSchema: Yup.object().shape({
      paymentDate: Yup.date()
        .max(new Date(), "Tanggal pembayaran tidak boleh di masa depan.")
        .required("Tanggal Pembayaran harus diisi."),
      paymentTime: Yup.string(),
      method: Yup.string(),
      refNumber: Yup.string().when("method", {
        is: (method) => method.toUpperCase() === "BANK",
        then: Yup.string(),
      }),
      bankName: Yup.string().when("method", {
        is: (method) => method.toUpperCase() === "BANK",
        then: Yup.string(),
      }),
      accountNumber: Yup.string().when("method", {
        is: (method) => method.toUpperCase() === "BANK",
        then: Yup.string(),
      }),
      totalPayment: Yup.number()
        .required("Total harus harus diisi")
        .min(1, "Jumlah Pembayaran harus lebih dari 0.")
        .when("mode", {
          is: 2,
          then: (schema) =>
            schema.max(
              invoice?.Customer?.credit,
              "Jumlah Pembayaran terlalu besar"
            ),
        }),
      paymentSource: Yup.number().required(
        "Sumber Pembayaran harus harus dipilih."
      ),
      mode: Yup.number().required("Sumber Pembayaran harus dipilih."),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      await api.payment
        .store(
          values.invoiceId,
          values.method,
          values.paymentDate,
          values.paymentTime,
          values.refNumber,
          values.bankName,
          values.accountNumber,
          values.totalPayment,
          values.status,
          values.mode
        )
        .catch((error) => {
          // console.log("eror: ", error);
          setPayloads("errorMessage.view", {
            title: "Gagal Register Pembayaran",
            message: error?.response?.data?.message,
          });
          isError = true;
          openModal("errorMessage.view");
          setIsLoading(false);
        });
    },
    onSuccess: () => {
      if (!isError) {
        setIsLoading(false);
        handleSuccess();
      }
    },
  });

  useEffect(() => {
    const kurangBayar =
      invoice?.total_amount - invoice?.total_payment - invoice?.credit_amount;
    if (kurangBayar < paymentAmount) {
      setPaymentStatus(" (Lebih Bayar)");
    } else if (kurangBayar > paymentAmount) {
      setPaymentStatus(" (Kurang Bayar)");
    } else {
      setPaymentStatus(" (Lunas)");
    }
  }, [paymentAmount]);

  function copyValue(setFieldValue, mode) {
    if (mode && mode == 2) {
      if (invoice && invoice.credit_amount > invoice.total_amount) {
        setFieldValue(
          "totalPayment",
          invoice?.total_amount -
            invoice?.total_payment -
            invoice?.credit_amount
        );
        setPaymentAmount(
          invoice?.total_amount -
            invoice?.total_payment -
            invoice?.credit_amount
        );
      }
    } else {
      setFieldValue(
        "totalPayment",
        invoice?.total_amount - invoice?.total_payment - invoice?.credit_amount
      );
      setPaymentAmount(
        invoice?.total_amount - invoice?.total_payment - invoice?.credit_amount
      );
    }
  }

  return (
    <ModalMolecule
      show={showStates["payment.create"]}
      title="Register Payment"
      onClose={handleClose}
    >
      <AppModals
        items={["errorMessage.view"]}
        onSuccess={{
          "errorMessage.view": () => fetch(),
        }}
      />
      <Formik
        {...{ initialValues, validationSchema, onSubmit }}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form className="space-y-2" onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-4">
              <InputMolecule
                label="Tanggal Pembayaran"
                type="date"
                name="paymentDate"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.paymentDate}
                errorMessage={
                  errors.paymentDate &&
                  touched.paymentDate &&
                  errors.paymentDate
                }
              />
              <InputMolecule
                label="Waktu Pembayaran"
                type="time"
                name="paymentTime"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.paymentTime}
                errorMessage={
                  errors.paymentTime &&
                  touched.paymentTime &&
                  errors.paymentTime
                }
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <SelectMolecule
                label="Sumber Pembayaran"
                placeholder="Pilih Status"
                options={[
                  {
                    value: 1,
                    label: "Pembayaran Customer",
                  },
                  {
                    value: 2,
                    label: "Kredit Customer",
                  },
                ]}
                name="mode"
                onChange={(e) => {
                  handleChange(e);
                  const mode = e.target.value;
                  if (mode === 1) {
                    setFieldValue("totalPayment", 0);
                  } else if (mode === 2) {
                    const outstandingAmount =
                      invoice?.total_amount -
                      invoice?.total_payment -
                      invoice?.credit_amount;
                    const customerCredit = invoice?.Customer?.credit;
                    setFieldValue(
                      "totalPayment",
                      outstandingAmount > customerCredit
                        ? customerCredit
                        : outstandingAmount
                    );
                  }
                }}
                onBlur={handleBlur}
                value={values.mode}
                errorMessage={errors.mode && touched.mode && errors.mode}
                absolute={false}
              />
              <div>
                <InputLabel>Kredit Yang Dimiliki Customer</InputLabel>
                <div className="h-11 flex items-center px-3 border border-gray-300 rounded-lg bg-gray-100 cursor-default">
                  <Typography.Currency
                    align="right"
                    number={invoice?.Customer?.credit}
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <>
                {values.mode === 1 && (
                  <InputMolecule
                    label="Jumlah Pembayaran"
                    type="number"
                    name="totalPayment"
                    onChange={(e) => {
                      handleChange(e);
                      setPaymentAmount(e.target.value);
                    }}
                    onBlur={handleBlur}
                    value={values.totalPayment}
                    errorMessage={
                      errors.totalPayment &&
                      touched.totalPayment &&
                      errors.totalPayment
                    }
                  />
                )}
                {values.mode === 2 && (
                  <div
                    onClick={(e) => {
                      copyValue(setFieldValue, values.mode);
                    }}
                  >
                    <InputLabel>Jumlah Pembayaran</InputLabel>
                    <div className="h-11 flex items-center px-3 border border-gray-300 rounded-lg bg-gray-100 cursor-default">
                      <Typography.Currency
                        align="right"
                        number={values.totalPayment}
                      />
                    </div>
                  </div>
                )}
              </>
              <div
                onClick={(e) => {
                  copyValue(setFieldValue, values.mode);
                }}
              >
                <InputLabel>Kurang Bayar</InputLabel>
                <div className="h-11 flex items-center px-3 border border-gray-300 rounded-lg bg-gray-100 cursor-default">
                  <Typography.Currency
                    align="right"
                    number={
                      invoice?.total_amount -
                      invoice?.total_payment -
                      invoice?.credit_amount
                    }
                  />
                </div>
              </div>
            </div>
            <div className="hidden">
              <InputMolecule
                label="Metode Pembayaran"
                type="text"
                name="method"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.method}
                errorMessage={errors.method && touched.method && errors.method}
              />
            </div>
            <InputMolecule
              label="Nomor Referensi"
              type="text"
              name="refNumber"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.refNumber}
              errorMessage={
                errors.refNumber && touched.refNumber && errors.refNumber
              }
            />
            <div className="grid grid-cols-2 gap-4">
              <InputMolecule
                label="Nama Bank"
                type="text"
                name="bankName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.bankName}
                errorMessage={
                  errors.bankName && touched.bankName && errors.bankName
                }
              />
              <InputMolecule
                label="No. Account"
                type="text"
                name="accountNumber"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.accountNumber}
                errorMessage={
                  errors.accountNumber &&
                  touched.accountNumber &&
                  errors.accountNumber
                }
              />
            </div>
            {/* <SelectMolecule
              label="Status Pembayaran"
              placeholder="Pilih Status"
              options={[
                {
                  value: true,
                  label: "OK",
                },
                {
                  value: false,
                  label: "Not OK",
                },
              ]}
              name="status"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.status}
              errorMessage={
                errors.status &&
                touched.status &&
                errors.status
              }
              absolute={false}
            /> */}
            <div className="fixed bottom-2 left-0 sm:left-auto right-0 sm:right-4 sm:bottom-4 sm:w-auto z-40 flex justify-end border-t border-gray-100 bg-white sm:bg-transparent p-4 sm:p-0 dark:border-gray-700 dark:bg-gray-800 print:hidden sm:absolute sm:rounded-b-lg sm:border-0 ">
              <Button type="submit" disabled={isLoading}>
                {isLoading ? "Loading..." : `Simpan ${paymentStatus}`}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </ModalMolecule>
  );
}

export default CreatePaymentModal;
