import { TrashIcon } from "@heroicons/react/24/solid";
import {
  Button,
  DottedOutlineButton,
  IconButton,
  BadgeButton,
} from "app/components/atoms/button";
import { FormHeader, FormSummary } from "app/components/atoms/layout";
import { PlainMultiselect } from "app/components/atoms/multiselect";
import { useState, useEffect } from "react";
import Table from "app/components/atoms/table";
import Typography from "app/components/atoms/typography";
import { InputMolecule } from "app/components/molecules/form";
import { ModalMolecule } from "app/components/molecules/modal";
import {
  SelectMolecule,
  SelectSearchMolecule,
} from "app/components/molecules/select";
import api from "app/integration/api";
import { useCollector, useCreateForm } from "app/integration/common/hooks";
import MainLayout from "app/layouts/main";
import clsx from "clsx";
import { FieldArray, Formik, getIn } from "formik";
import str from "lib/str";
import { useNavigate } from "react-router-dom";
import style from "style/height.module.css";
import {
  filter as _filter,
  findWhere as _findWhere,
  flatten as _flatten,
  where as _where,
} from "underscore";
import * as Yup from "yup";
import { Card } from "app/components/atoms/card";
import { InputLabel } from "app/components/atoms/form";
import { Badge } from "app/components/atoms/badge";
import { useApplicationModals } from "app/common/hooks";
import AppModals from "app/modals";

const breadcrumbItems = [
  { label: "Sales", href: "/sales_order" },
  { label: "Order", href: "/sales_order" },
  { label: "Tambah Order", href: "#" },
];

function CreateSalesOrderPage() {
  const navigate = useNavigate();

  const handleSuccess = (res) => {
    console.log(res);
    navigate("/sales_order/update/" + res.data.id);
  };

  const [isLoading, setIsloading] = useState(false);
  const [duplicateOrder, setDuplicateOrder] = useState(false);
  const [confirmedDuplicateOrder, setConfirmedDuplicateOrder] = useState(false);

  const [showInvoice, setShowInvoice] = useState(false);

  const [salesOrders, setSalesOrders, salesOrdersLoader, collectSalesOrders] =
    useCollector({ modelName: "salesOrder" });

  const [invoices, setInvoices, invoicesLoader, collectInvoices] = useCollector(
    { modelName: "invoice" }
  );

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  // console.log(today);

  const { initialValues, validationSchema, onSubmit } = useCreateForm({
    initialValues: {
      customerId: null,
      deliveryTo: null,
      addressId: null,
      deliveryDate: "",
      noteOrder: "",
      orderDetails: [
        {
          dailyMenuId: null,
          variantItemIds: [],
          qty: 1,
          unitPrice: 0,
        },
      ],
      deliveryTime: 1,
      parkingFee: 0,
    },
    validationSchema: Yup.object().shape({
      customerId: Yup.number().required("Customer harus diisi"),
      deliveryTo: Yup.number().required("Customer Tujuan harus diisi"),
      addressId: Yup.number().required("Alamat harus diisi"),
      deliveryTime: Yup.number().required("Waktu Kirim harus diisi"),
      deliveryDate: Yup.date()
        .required("Tanggal Kirim harus diisi")
        .min(today, "Tanggal pengiriman tidak boleh di masa lalu."),
      orderDetails: Yup.array()
        .of(
          Yup.object().shape({
            dailyMenuId: Yup.number().required("Menu harus diisi"),
            variantItemIds: Yup.array().of(Yup.number()),
            qty: Yup.number().required("Qty harus diisi"),
            unitPrice: Yup.number().required("Harga harus diisi"),
          })
        )
        .min(1, "Minimal harus ada 1 Item yang dibeli."),
      parkingFee: Yup.number().max(
        process.env.REACT_APP_MAX_PARKING_FEE,
        `Biaya parkir tidak boleh lebih dari ${process.env.REACT_APP_MAX_PARKING_FEE}`
      ),
    }),
    onSubmit: async (values) => {
      try {
        const deliveryDate = new Date(values.deliveryDate).toISOString();
        const filteredSalesOrders = _where(salesOrders, {
          delivery_date: deliveryDate,
          customer_id: values.customerId,
          delivery_to: values.deliveryTo,
          address_id: values.addressId,
        });

        // Ada order yang mirip gak?
        if (filteredSalesOrders.length > 0) {
          setDuplicateOrder(true);

          // User kalau belum konfirmasi bikin order yang sama, jangan bikin order nya
          if (!confirmedDuplicateOrder) {
            return;
          }
        }

        // Cek apakah punya kategori ongkir atau tidak, kalau ga punya, jangan submit order nya
        if (
          localHelpers.selectedShipmentCostCategory(
            values.deliveryTo,
            values.addressId
          ) !== null
        ) {
          setIsloading(true);
          let response = await api.salesOrder.store(
            values.customerId,
            values.deliveryTo,
            values.addressId,
            values.deliveryTime,
            values.deliveryDate,
            values.noteOrder,
            values.parkingFee,
            localHelpers.selectedShipmentCostCategory(
              values.deliveryTo,
              values.addressId
            )?.price,
            localHelpers.shipmentCostDiscount(
              values.deliveryTo,
              values.addressId,
              values.orderDetails
                .map((orderDetail) => orderDetail.qty)
                .reduce((partialSum, a) => partialSum + a, 0)
            ),
            values.orderDetails.map((orderDetail) => ({
              product_id: orderDetail.dailyMenuId,
              qty: orderDetail.qty,
              unit_price: orderDetail.unitPrice,
              notes: orderDetail.notes,
              variants: orderDetail.variantItemIds.map((variantItemId) => {
                const selectedVariantItem =
                  localHelpers.variantItemById(variantItemId);
                return {
                  variant_category_id: selectedVariantItem.id,
                  name: selectedVariantItem.name,
                  price: selectedVariantItem.price,
                };
              }),
            }))
          );
          return response;
        } else {
          setPayloads("errorMessage.view", {
            title: "Tambah Order Error",
            message:
              "Customer ini tidak memiliki Kategori Ongkir. Harap set Kategori Ongkir untuk Customer ini terlebih dahulu.",
          });
          openModal("errorMessage.view");
        }
      } catch (error) {
        setIsloading(false);
      }
    },
    onSuccess: handleSuccess,
  });

  const [customers] = useCollector({ modelName: "customer" });
  const [dailyMenus] = useCollector({ modelName: "dailyMenu" });
  const [variants] = useCollector({ modelName: "variant" });

  const { openModal, setPayloads } = useApplicationModals();
  const [currentErrors, setCurrentErrors] = useState([]);

  function runValidations(values) {
    validationSchema
      .validate(values, { abortEarly: false })
      .then((responseData) => {
        console.log("no validation errors");
        console.log(responseData);
        setCurrentErrors([]);
      })
      .catch((err) => {
        console.log(err);
        console.log(err.name); // ValidationError
        console.log(err.errors);
        setCurrentErrors(err.errors);

        setPayloads("errorMessage.view", {
          title: "Tambah Order Error",
          data: err.errors,
        });
        openModal("errorMessage.view");
      });
  }

  const localHelpers = {
    unselectedDailyMenusWithCurrentSelectedValue: (
      orderDetails,
      currentSelectedDailyMenuId,
      requestedDate
    ) => {
      const selectedDailyMenuIds = orderDetails?.map(
        (orderDetail) => orderDetail.dailyMenuId
      );
      // const filteredDailyMenus = _filter(
      //   dailyMenus,
      //   (dailyMenu) =>
      //     (currentSelectedDailyMenuId !== undefined &&
      //       currentSelectedDailyMenuId !== null &&
      //       dailyMenu.id === currentSelectedDailyMenuId) ||
      //     !selectedDailyMenuIds.includes(dailyMenu.id)
      // );
      const filteredDailyMenus = dailyMenus;
      return _filter(
        filteredDailyMenus,
        (dailyMenu) =>
          str.standardDate(dailyMenu.delivery_date) ===
          str.standardDate(requestedDate)
      );
    },
    variantItemsByVariantId: (variantId) => {
      const variantCategories = _findWhere(variants, {
        id: variantId,
      })?.VariantCategories;
      const variantItems = _flatten(
        variantCategories?.map((variantCategory) => variantCategory.Variants)
      );
      return variantItems;
    },
    variantItemById: (variantItemId) => {
      const variantItems = _flatten(
        variants.map((variant) => {
          return _flatten(
            variant.VariantCategories.map(
              (variantCategory) => variantCategory.Variants
            )
          );
        })
      );
      return _findWhere(variantItems, { id: variantItemId });
    },
    subtotal: (orderItems) => {
      let subtotal = orderItems
        .map((orderItem) => +orderItem.qty * +orderItem.unitPrice)
        .reduce((partialSum, a) => partialSum + a, 0);
      let variantTotal = orderItems
        .map((orderItem) => {
          return (
            orderItem.variantItemIds
              .map((variantItemId) =>
                localHelpers.variantItemById(variantItemId)
              )
              .map((variantItem) => +variantItem.price)
              .reduce((partialSum, a) => partialSum + a, 0) * orderItem.qty
          );
        })
        .reduce((partialSum, a) => partialSum + a, 0);

      return +subtotal + +variantTotal;
    },
    selectedShipmentCostCategory: (deliveryTo, addressId) => {
      return _findWhere(_findWhere(customers, { id: deliveryTo })?.Addresses, {
        id: addressId,
      })?.DeliveryCategory;
    },
    shipmentCostDiscount: (deliveryTo, addressId, qty) => {
      const selectedShipmentCostCategory =
        localHelpers.selectedShipmentCostCategory(deliveryTo, addressId);
      const deliveryDiscounts = selectedShipmentCostCategory?.DeliveryDiscounts;
      if (deliveryDiscounts?.length === 0) return 0;

      for (let i = qty; i >= 0; i--) {
        const deliveryDiscount = _findWhere(deliveryDiscounts, {
          condition: i + "",
        });
        if (deliveryDiscount !== undefined) {
          return deliveryDiscount.discount_price;
        }
      }

      return 0;
    },
  };

  function customerInvoices(customerId) {
    return _where(invoices, { customer_id: customerId });
  }

  return (
    <MainLayout
      activeSidebarNavigation="order"
      breadcrumbItems={breadcrumbItems}
      pageTitle="Tambah Order"
      headingButtons={[]}
    >
      <AppModals
        items={["errorMessage.view"]}
        onSuccess={{
          "errorMessage.view": () => fetch(),
        }}
      />
      <Formik
        {...{ initialValues, validationSchema, onSubmit }}
        enableReinitialize={false}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form
            className={clsx(
              "px-2 overflow-y-auto",
              style["main-content-height"]
            )}
            onSubmit={handleSubmit}
          >
            {console.log(values)}
            <FormHeader>
              <InputMolecule
                label="Tanggal Kirim"
                type="date"
                name="deliveryDate"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.deliveryDate}
                errorMessage={
                  errors.deliveryDate &&
                  touched.deliveryDate &&
                  errors.deliveryDate
                }
              />
              <SelectSearchMolecule
                label="Customer"
                name="customerId"
                options={customers?.map((customer) => ({
                  value: customer.id,
                  label:
                    customer.first_name +
                    " " +
                    customer.middle_name +
                    " " +
                    customer.last_name +
                    (customer.nick_name ? " (" + customer.nick_name + ")" : ""),
                }))}
                onChange={(e) => {
                  handleChange(e);
                  setFieldValue("deliveryTo", e.target.value);
                  const customer = _findWhere(customers, {
                    id: e.target.value,
                  });
                  setFieldValue("addressId", customer?.Addresses[0]?.id);
                  setFieldValue(
                    "noteOrder",
                    customer?.Addresses[0]?.note_order
                  );
                  setFieldValue(
                    "deliveryTime",
                    customer?.Addresses[0]?.delivery_time
                  );
                  setFieldValue(
                    "parkingFee",
                    customer?.Addresses[0]?.parking_fee
                  );
                }}
                onBlur={handleBlur}
                value={values.customerId}
                errorMessage={
                  errors.customerId && touched.customerId && errors.customerId
                }
              />
              <SelectSearchMolecule
                label="Customer Tujuan"
                name="deliveryTo"
                options={customers
                  ?.sort(function (a, b) {
                    if (
                      a.first_name?.toLowerCase() < b.first_name?.toLowerCase()
                    )
                      return -1;
                    if (
                      a.first_name?.toLowerCase() > b.first_name?.toLowerCase()
                    )
                      return 1;
                    return 0;
                  })
                  ?.map((customer) => ({
                    value: customer.id,
                    label:
                      customer.first_name +
                      " " +
                      customer.middle_name +
                      " " +
                      customer.last_name +
                      (customer.nick_name
                        ? " (" + customer.nick_name + ")"
                        : ""),
                  }))}
                onChange={(e) => {
                  handleChange(e);
                  const customer = _findWhere(customers, {
                    id: e.target.value,
                  });
                  setFieldValue("addressId", customer?.Addresses[0]?.id);
                  setFieldValue(
                    "noteOrder",
                    customer?.Addresses[0]?.note_order
                  );
                  setFieldValue(
                    "deliveryTime",
                    customer?.Addresses[0]?.delivery_time
                  );
                  setFieldValue(
                    "parkingFee",
                    customer?.Addresses[0]?.parking_fee
                  );
                }}
                onBlur={handleBlur}
                value={values.deliveryTo}
                errorMessage={
                  errors.deliveryTo && touched.deliveryTo && errors.deliveryTo
                }
              />
              <SelectMolecule
                label="Alamat Kirim"
                name="addressId"
                options={_findWhere(customers, {
                  id: values.deliveryTo,
                })?.Addresses?.map((address) => ({
                  value: address.id,
                  label: address.address,
                }))}
                // onChange={handleChange}
                onChange={(e) => {
                  handleChange(e);
                  const customer = _findWhere(customers, {
                    id: values.deliveryTo,
                  });
                  console.log("selected id: ", e.target.value);
                  setFieldValue(
                    "noteOrder",
                    customer?.Addresses.find(obj => {
                      return obj.id === e.target.value;
                    })?.note_order
                  );
                  setFieldValue(
                    "deliveryTime",
                    customer?.Addresses.find(obj => {
                      return obj.id === e.target.value;
                    })?.delivery_time
                  );
                  setFieldValue(
                    "parkingFee",
                    customer?.Addresses.find(obj => {
                      return obj.id === e.target.value;
                    })?.parking_fee
                  );
                }}
                onBlur={handleBlur}
                value={values.addressId}
                errorMessage={
                  errors.addressId && touched.addressId && errors.addressId
                }
              />
              <SelectMolecule
                label="Waktu Kirim"
                name="deliveryTime"
                options={[
                  { value: 0, label: "Pagi" },
                  { value: 1, label: "Default" },
                ]}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.deliveryTime}
                errorMessage={
                  errors.deliveryTime &&
                  touched.deliveryTime &&
                  errors.deliveryTime
                }
              />
            </FormHeader>

            <div className="py-2 overflow-visible">
              <div className="w-full">
                <FieldArray name="orderDetails">
                  {(arrayHelpers) => (
                    <div className="table-content-height overflow-y-auto">
                      <Table.Wrapper asIndex={false} enableScroll={false}>
                        <Table.Header>
                          <Table.HeaderRow>
                            <Table.Heading>Menu</Table.Heading>
                            <Table.Heading>Varian</Table.Heading>
                            <Table.Heading>Catatan</Table.Heading>
                            <Table.Heading>Qty</Table.Heading>
                            <Table.Heading>
                              <p className="text-right">Harga Satuan</p>
                            </Table.Heading>
                            <Table.Heading>
                              <p className="text-right">Subtotal</p>
                            </Table.Heading>
                            <Table.Heading></Table.Heading>
                          </Table.HeaderRow>
                        </Table.Header>
                        <Table.Body>
                          {values.orderDetails?.map((orderDetail, key) => (
                            <Table.BodyRow key={key}>
                              <Table.Cell className="w-48">
                                <SelectMolecule
                                  hideLabel={true}
                                  name={`orderDetails[${key}].dailyMenuId`}
                                  options={localHelpers
                                    .unselectedDailyMenusWithCurrentSelectedValue(
                                      values.orderDetails,
                                      orderDetail.dailyMenuId,
                                      values.deliveryDate
                                    )
                                    ?.sort(function (a, b) {
                                      if (
                                        a.ProductCategory.name?.toLowerCase() <
                                        b.ProductCategory.name?.toLowerCase()
                                      )
                                        return -1;
                                      if (
                                        a.ProductCategory.name?.toLowerCase() >
                                        b.ProductCategory.name?.toLowerCase()
                                      )
                                        return 1;
                                      return 0;
                                    })
                                    ?.map((dailyMenu) => ({
                                      value: dailyMenu.id,
                                      label:
                                        dailyMenu.ProductCategory.name +
                                        ": " +
                                        dailyMenu.name,
                                    }))}
                                  onChange={(e) => {
                                    handleChange(e);
                                    setFieldValue(
                                      `orderDetails[${key}].unitPrice`,
                                      _findWhere(dailyMenus, {
                                        id: e.target.value,
                                      }).price
                                    );
                                  }}
                                  onBlur={handleBlur}
                                  value={orderDetail.dailyMenuId}
                                  errorMessage={
                                    getIn(
                                      errors,
                                      `orderDetails[${key}].dailyMenuId`
                                    ) &&
                                    getIn(
                                      touched,
                                      `orderDetails[${key}].dailyMenuId`
                                    ) &&
                                    getIn(
                                      errors,
                                      `orderDetails[${key}].dailyMenuId`
                                    )
                                  }
                                  absolute={true}
                                />
                              </Table.Cell>
                              <Table.Cell className="max-w-xs">
                                <VariantModal
                                  name={`orderDetails[${key}].variantItemIds`}
                                  options={_findWhere(variants, {
                                    id: _findWhere(dailyMenus, {
                                      id: orderDetail.dailyMenuId,
                                    })?.m_variant_id,
                                  })}
                                  onChange={handleChange}
                                  value={orderDetail.variantItemIds}
                                  key={"variant-modal-" + key}
                                />
                                {/* {_findWhere(dailyMenus, {
                                  id: orderDetail.dailyMenuId,
                                })?.m_variant_id !== undefined && (
                                  <PlainMultiselect
                                    name={`orderDetails[${key}].variantItemIds`}
                                    options={localHelpers
                                      .variantItemsByVariantId(
                                        _findWhere(dailyMenus, {
                                          id: orderDetail.dailyMenuId,
                                        })?.m_variant_id
                                      )
                                      .map((variantItem) => ({
                                        value: variantItem.id,
                                        label:
                                          variantItem.name +
                                          " " +
                                          variantItem.price,
                                      }))}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={orderDetail.variantItemIds}
                                    errorMessage={
                                      getIn(
                                        errors,
                                        `orderDetails[${key}].variantItemIds`
                                      ) &&
                                      getIn(
                                        touched,
                                        `orderDetails[${key}].variantItemIds`
                                      ) &&
                                      getIn(
                                        errors,
                                        `orderDetails[${key}].variantItemIds`
                                      )
                                    }
                                  />
                                )} */}
                              </Table.Cell>
                              <Table.Cell>
                                <InputMolecule
                                  hideLabel={true}
                                  type="text"
                                  name={`orderDetails[${key}].notes`}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={orderDetail.notes}
                                  errorMessage={
                                    getIn(
                                      errors,
                                      `orderDetails[${key}].notes`
                                    ) &&
                                    getIn(
                                      touched,
                                      `orderDetails[${key}].notes`
                                    ) &&
                                    getIn(errors, `orderDetails[${key}].notes`)
                                  }
                                />
                              </Table.Cell>
                              <Table.Cell className="w-24">
                                <InputMolecule
                                  hideLabel={true}
                                  type="number"
                                  name={`orderDetails[${key}].qty`}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={orderDetail.qty}
                                  errorMessage={
                                    getIn(errors, `orderDetails[${key}].qty`) &&
                                    getIn(
                                      touched,
                                      `orderDetails[${key}].qty`
                                    ) &&
                                    getIn(errors, `orderDetails[${key}].qty`)
                                  }
                                />
                              </Table.Cell>
                              <Table.Cell>
                                {/* <InputMolecule
                                  hideLabel={true}
                                  type="number"
                                  name={`orderDetails[${key}].unitPrice`}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={orderDetail.unitPrice}
                                  errorMessage={
                                    getIn(
                                      errors,
                                      `orderDetails[${key}].unitPrice`
                                    ) &&
                                    getIn(
                                      touched,
                                      `orderDetails[${key}].unitPrice`
                                    ) &&
                                    getIn(
                                      errors,
                                      `orderDetails[${key}].unitPrice`
                                    )
                                  }
                                /> */}
                                <p className="text-right">
                                  <Typography.Currency
                                    number={orderDetail.unitPrice}
                                  />
                                </p>
                              </Table.Cell>
                              <Table.Cell>
                                <p className="text-right">
                                  <Typography.Currency
                                    number={
                                      orderDetail.qty *
                                      (+orderDetail.unitPrice +
                                        +orderDetail.variantItemIds
                                          .map((variantItemId) => {
                                            const selectedVariantItem =
                                              localHelpers.variantItemById(
                                                variantItemId
                                              );
                                            return +selectedVariantItem?.price;
                                          })
                                          .reduce(
                                            (partialSum, a) => partialSum + a,
                                            0
                                          ))
                                    }
                                  />
                                </p>
                              </Table.Cell>
                              <Table.Cell>
                                <IconButton
                                  icon={TrashIcon}
                                  onClick={(e) => arrayHelpers.remove(key)}
                                />
                              </Table.Cell>
                            </Table.BodyRow>
                          ))}
                        </Table.Body>
                      </Table.Wrapper>
                      <div>
                        <div className="">
                          <DottedOutlineButton
                            type="button"
                            onClick={(e) =>
                              arrayHelpers.push({
                                dailyMenuId: null,
                                variantItemIds: [],
                                notes: "",
                                qty: 1,
                                unitPrice: 0,
                              })
                            }
                          >
                            Tambah Item
                          </DottedOutlineButton>
                        </div>
                      </div>
                    </div>
                  )}
                </FieldArray>
                <FormSummary>
                  <div className="flex-grow w-full md:max-w-lg order-last md:order-first flex flex-col justify-between gap-2">
                    <InputMolecule
                      label="Catatan Pengiriman"
                      type="text"
                      name="noteOrder"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.noteOrder}
                      errorMessage={
                        errors.noteOrder &&
                        touched.noteOrder &&
                        errors.noteOrder
                      }
                    />
                    {values.customerId && (
                      <div className="w-fit">
                        <BadgeButton onClick={(e) => setShowInvoice(true)}>
                          Lihat Invoice Outstanding{" ("}
                          {
                            customerInvoices(values.customerId)?.filter(
                              (invoice) => invoice.status !== 3
                            ).length
                          }
                          {")"}
                        </BadgeButton>
                      </div>
                    )}
                  </div>
                  <div className="h-full grid grid-cols-2 items-center gap-y-1 gap-x-2 text-right w-fit">
                    <div className="text-xs uppercase text-gray-600 text-right font-light">
                      Subtotal:{" "}
                    </div>
                    <Typography.Currency
                      number={localHelpers.subtotal(values.orderDetails)}
                    />
                    <div className="text-xs uppercase text-gray-600 text-right font-light">
                      Ongkir:{" "}
                    </div>
                    <Typography.Currency
                      number={
                        localHelpers.selectedShipmentCostCategory(
                          values.deliveryTo,
                          values.addressId
                        )?.price
                      }
                    />
                    <div className="text-xs uppercase text-gray-600 text-right font-light">
                      Parkir:{" "}
                    </div>
                    <InputMolecule
                      type="number"
                      name="parkingFee"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.parkingFee}
                      errorMessage={
                        errors.parkingFee &&
                        touched.parkingFee &&
                        errors.parkingFee
                      }
                    />
                    <div className="text-xs uppercase text-gray-600 text-right font-light">
                      Diskon Ongkir:{" "}
                    </div>
                    <Typography.Currency
                      number={localHelpers.shipmentCostDiscount(
                        values.deliveryTo,
                        values.addressId,
                        values.orderDetails
                          .map((orderDetail) => orderDetail.qty)
                          .reduce((partialSum, a) => partialSum + a, 0)
                      )}
                    />
                    <div className="text-xs uppercase text-gray-600 text-right font-bold">
                      Total:{" "}
                    </div>
                    <Typography.Currency
                      number={
                        +localHelpers.subtotal(values.orderDetails) +
                        +localHelpers.selectedShipmentCostCategory(
                          values.deliveryTo,
                          values.addressId
                        )?.price -
                        +localHelpers.shipmentCostDiscount(
                          values.deliveryTo,
                          values.addressId,
                          values.orderDetails
                            .map((orderDetail) => orderDetail.qty)
                            .reduce((partialSum, a) => partialSum + a, 0)
                        ) +
                        Number(values.parkingFee)
                      }
                    />
                  </div>
                </FormSummary>
              </div>
            </div>

            <ModalMolecule
              show={showInvoice}
              title="Invoice yang belum lunas"
              onClose={() => setShowInvoice(false)}
            >
              {customerInvoices(values.customerId)?.filter(
                (invoice) => invoice.status !== 3
              ).length > 0 ? (
                <Table.Wrapper>
                  <Table.Header>
                    <Table.HeaderRow>
                      <Table.Heading>Kode</Table.Heading>
                      <Table.Heading align="right">Total Tagihan</Table.Heading>
                      <Table.Heading align="right">Pembayaran</Table.Heading>
                      <Table.Heading align="right">Kredit</Table.Heading>
                      <Table.Heading align="right">Kurang/Lebih</Table.Heading>
                    </Table.HeaderRow>
                  </Table.Header>
                  <Table.Body>
                    {customerInvoices(values.customerId)?.map(
                      (invoice, key) => {
                        if (invoice.status !== 3) {
                          return (
                            <Table.BodyRow key={key}>
                              <Table.Cell>
                                <BadgeButton
                                  onClick={(e) =>
                                    navigate("/invoice/detail/" + invoice.id)
                                  }
                                >
                                  {invoice.inv_code}
                                </BadgeButton>
                              </Table.Cell>
                              <Table.Cell align="right">
                                <Typography.Currency
                                  number={invoice.total_amount}
                                ></Typography.Currency>
                              </Table.Cell>
                              <Table.Cell align="right">
                                <Typography.Currency
                                  number={invoice.total_payment}
                                ></Typography.Currency>
                              </Table.Cell>
                              <Table.Cell align="right">
                                <Typography.Currency
                                  number={invoice.credit_amount}
                                ></Typography.Currency>
                              </Table.Cell>
                              <Table.Cell align="right">
                                <Typography.Currency
                                  color={
                                    Number(invoice.total_amount) >
                                    Number(invoice.total_payment) +
                                      Number(invoice.credit_amount)
                                      ? "red"
                                      : "green"
                                  }
                                  number={
                                    Number(invoice.total_payment) +
                                    Number(invoice.credit_amount) -
                                    Number(invoice.total_amount)
                                  }
                                />
                              </Table.Cell>
                            </Table.BodyRow>
                          );
                        }
                      }
                    )}
                  </Table.Body>
                </Table.Wrapper>
              ) : (
                <p>Tidak ada invoice yang belum lunas.</p>
              )}
            </ModalMolecule>

            <ModalMolecule
              show={duplicateOrder && !confirmedDuplicateOrder}
              title="Duplicate Order"
              onClose={() => {
                setConfirmedDuplicateOrder(false);
                setDuplicateOrder(false);
              }}
            >
              <p>
                Terdapat order untuk <strong>customer yang sama</strong> di{" "}
                <strong>hari yang sama</strong>. Apakah Anda ingin tetap
                melanjutkan?
              </p>
              <div className="fixed bottom-2 left-0 sm:left-auto right-0 sm:right-4 sm:bottom-4 sm:w-auto z-40 flex justify-end border-t border-gray-100 bg-white sm:bg-transparent p-4 sm:p-0 dark:border-gray-700 dark:bg-gray-800 print:hidden sm:absolute sm:rounded-b-lg sm:border-0 ">
                <Button
                  onClick={() => {
                    setConfirmedDuplicateOrder(true);
                    return true;
                  }}
                  type="submit"
                >
                  Lanjut
                </Button>
              </div>
            </ModalMolecule>

            <div className="fixed bottom-2 left-0 right-0 sm:absolute sm:left-auto sm:right-4 sm:bottom-4 sm:w-auto z-40 flex justify-end border-t border-gray-100 bg-white sm:bg-transparent p-4 sm:p-0 dark:border-gray-700 dark:bg-gray-800 print:hidden sm:rounded-b-lg sm:border-0 ">
              <Button
                onClick={() => runValidations(values)}
                type="submit"
                disabled={isLoading}
              >
                {/* Tambah Order{confirmedDuplicateOrder && " (duplikat)"} */}
                {isLoading
                  ? "Loading..."
                  : `Tambah Order${
                      confirmedDuplicateOrder ? " (duplikat)" : ""
                    }`}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </MainLayout>
  );
}

function VariantModal({ name, options = [], onChange = () => {}, value = [] }) {
  const [showVariantModal, setShowVariantModal] = useState(false);

  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(localValue)) {
      setLocalValue(value);
    }
  }, [value]);

  const localOnChange = (newValue) => {
    setLocalValue(newValue);
    onChange({
      target: {
        value: newValue,
        name: name,
      },
    });
  };

  return (
    <div className="flex flex-wrap items-center gap-1">
      {localValue.map((id, key) => (
        <Badge color="green" key={key}>
          {/* {
              JSON.stringify(
                _flatten(options?.VariantCategories?.map(variantCategory => variantCategory.Variants))
              )
            } */}
          {
            _findWhere(
              _flatten(
                options?.VariantCategories?.map(
                  (variantCategory) => variantCategory.Variants
                )
              ),
              { id }
            )?.name
          }{" "}
          (
          <Typography.Currency
            fontSize="xs"
            number={
              _findWhere(
                _flatten(
                  options?.VariantCategories?.map(
                    (variantCategory) => variantCategory.Variants
                  )
                ),
                { id }
              )?.price
            }
          />
          )
        </Badge>
      ))}
      <div className="inline-flex space-x-2"></div>
      <BadgeButton
        onClick={(e) => {
          setShowVariantModal(true);
        }}
      >
        Pilih Varian
      </BadgeButton>
      <ModalMolecule
        show={showVariantModal}
        title="Pilih Varian"
        onClose={(e) => {
          setShowVariantModal(false);
        }}
        useHideButton={false}
      >
        <div className="grid grid-cols-3 items-center gap-y-1 gap-x-2 w-fit">
          <div className="uppercase text-xs font-light text-left text-gray-500">
            Varian:
          </div>
          <div className="text-xs font-semibold text-left col-span-2">
            {options.name}
          </div>
          <div className="uppercase text-xs font-light text-left text-gray-500">
            Deskripsi:
          </div>
          <div className="text-xs font-semibold text-left col-span-2">
            {options.desc}
          </div>
        </div>
        {options?.VariantCategories?.map((variantCategory, key) => (
          <Card key={key}>
            <div className="flex items-center gap-2">
              <InputLabel>{variantCategory.name}</InputLabel>
              <InputLabel>
                (
                {variantCategory.is_multiple
                  ? "Boleh Lebih Dari Satu"
                  : "Pilih Salah Satu"}
                )
              </InputLabel>
            </div>
            <div className="grid grid-cols-2 gap-1">
              {variantCategory?.Variants?.map((variantItem, vKey) => (
                <div key={vKey} className="gap-2 flex items-center">
                  <input
                    type="checkbox"
                    checked={localValue.includes(variantItem.id)}
                    onChange={(e) => {
                      const value = e.target.checked;
                      if (value) {
                        const newValue = localValue;
                        if (!variantCategory.is_multiple) {
                          variantCategory?.Variants?.map((variantItem) => {
                            const index = newValue.indexOf(variantItem.id);
                            if (index > -1) {
                              newValue.splice(index, 1);
                            }
                          });
                        }
                        newValue.push(variantItem.id);
                        localOnChange(newValue);
                      } else {
                        const index = localValue.indexOf(variantItem.id);
                        if (index > -1) {
                          const newValue = localValue;
                          newValue.splice(index, 1);
                          localOnChange(newValue);
                        }
                      }
                    }}
                  />
                  <span className="text-xs capitalize">{variantItem.name}</span>
                  <span className="text-xs capitalize">
                    (
                    <Typography.Currency
                      fontSize="xs"
                      number={variantItem.price}
                    />
                    )
                  </span>
                </div>
              ))}
            </div>
          </Card>
        ))}
      </ModalMolecule>
    </div>
  );
}

export default CreateSalesOrderPage;
