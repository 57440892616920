import { useApplicationModals } from "app/common/hooks";
import { useEffect, useState } from "react";
import { ModalMolecule } from "app/components/molecules/modal";
import { useFetcher } from "app/integration/common/hooks";
import LogoSL from "img/sl-logo-fit.PNG";
import { DateTime } from "luxon";
import { Badge } from "app/components/atoms/badge";
import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Button } from "app/components/atoms/button";

function CreateSingleDeliverySlipModal({ onSuccess = () => {} }) {
  const { showStates, getPayloads, closeModal } = useApplicationModals();
  const payload = getPayloads("singleDeliverySlip.create");
    console.log("payload is: ", payload);

    const handleClose = () => closeModal("singleDeliverySlip.create");
    const handleSuccess = () => {
      closeModal("singleDeliverySlip.create");
      onSuccess();
    };

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });

    function getMenuQty(orderItems, menu) {
      var qty = 0;
      orderItems?.map((orderItem) => {
        if (orderItem.menu === menu) {
          qty += orderItem.qty;
        }
      });
      return qty;
    }

    function getProductNameQty(listProduct) {
      var menu = [
        {
          menu: "A",
          name: "A",
          qty: 0,
        },
        {
          menu: "B",
          name: "B",
          qty: 0,
        },
      ];

      if (listProduct?.length > 0) {
        listProduct.map((product, index) => {
          if (product.Product.ProductCategory.name === "A") {
            menu[0].qty += Number(product.qty);
            menu[0].name = product.Product.name;
          }
          if (product.Product.ProductCategory.name === "B") {
            menu[1].qty += Number(product.qty);
            menu[1].name = product.Product.name;
          }
        });
      }

      return menu;
    }

    // const menu = getProductNameQty(report);

    return (
      <ModalMolecule
        show={showStates["singleDeliverySlip.create"]}
        title="Print Surat Jalan"
        onClose={handleClose}
        size="xl"
      >
        <div
          ref={componentRef}
          className="text-sm font-light divide-y divide-dashed divide-gray-500 w-[827px] space-y-1 border border-gray-100 shadow-xl print:shadow-none print:border-none"
        >
          {payload?.data?.id > 0 && (
            <div className="block py-2 px-8">
              <div className="h-[208px] w-full border border-gray-800 flex flex-col px-2 mt-1 relative overflow-clip">
                <div className="absolute top-1 right-0">
                  <div className="w-full flex justify-end pr-1">
                    {payload?.data?.delivery_time === 0 ? (
                      <Badge color="green">Kirim Pagi</Badge>
                    ) : (
                      <></>
                    )}
                    {payload?.data?.is_valid_order ? (
                      <></>
                    ) : (
                      <Badge color="red">Invalid</Badge>
                    )}
                  </div>
                  <div className="border border-gray-100 px-1 mt-1 mr-1">
                    {getProductNameQty(payload?.data?.OrderItems).map(
                      (menu) => (
                        <div className="flex flex-row text-sm text-gray-600">
                          <p className="text-center">Total ({menu.menu}):</p>
                          <p className="w-6 text-right">{menu.qty}</p>
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-2 justify-between text-sm leading-tight">
                  <div className="">
                    <div className="flex items-center gap-1 pr-1">
                      <p className="w-20 text-gray-500">Order:</p>
                      <p>{payload?.data?.order_code}</p>
                    </div>
                    <div className="flex items-center gap-1 pr-1">
                      <p className="w-20 text-gray-500">Customer:</p>
                      <p className="font-medium">
                        {payload?.data?.Customer?.first_name}{" "}
                        {payload?.data?.Customer?.middle_name}{" "}
                        {payload?.data?.Customer?.last_name}
                      </p>
                    </div>
                    <div className="flex items-start gap-1 pr-1">
                      <p className="text-sm tracking-tighter leading-tight w-20 text-gray-500">
                        Alamat:
                      </p>
                      <p className="text-sm tracking-tighter leading-tight">
                        {payload?.data?.Address?.address}
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="flex items-center gap-1 pr-1">
                      <p className="w-12 text-gray-500">Tgl:</p>
                      <p>
                        {DateTime.fromISO(
                          payload?.data?.delivery_date
                        ).toFormat("dd-MM-yyyy")}
                      </p>
                    </div>
                    <div className="flex items-center gap-1 pr-1">
                      <p className="w-12 text-gray-500">Tel:</p>
                      <input
                        className="peer print:hidden accent-primary-600"
                        type="checkbox"
                        name={`rekappayload.data.showPhone`}
                        id={`rekappayload.data.showPhone`}
                        defaultChecked={false}
                      />
                      <p
                        htmlFor={`rekappayload.data.showPhone`}
                        className="peer-checked:print:block peer-checked:blur-none blur-sm print:hidden"
                      >
                        {payload?.data?.Customer?.phone_number}
                      </p>
                    </div>
                    <div className="flex items-start gap-1 pr-1">
                      <p className="text-sm tracking-tighter leading-tight w-12 text-gray-500">
                        Note:
                      </p>
                      <p className="text-sm tracking-tighter leading-tight pr-20">
                        {payload?.data?.note_order}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row flex-grow">
                  <div className="flex-grow flex flex-col">
                    <div className="flex-grow flex flex-col px-2 py-1 max-h-32 overflow-clip">
                      <div className="flex justify-between h-full">
                        <div className="w-full max-w-md">
                          {payload?.data?.OrderItems?.map((menu) => (
                            <div className="flex flex-row justify-between gap-1 w-full leading-tight">
                              <p className="flex-grow line-clamp-2 text-sm leading-tight">
                                {menu.Product?.name}{" "}
                                {menu.variants.length > 0 ? (
                                  <span className="text-sm tracking-tight text-gray-900 leading-tight">
                                    (
                                    {menu.variants
                                      .map((item) => item.name)
                                      .join(", ")}
                                    ){" "}
                                  </span>
                                ) : (
                                  ""
                                )}
                                {menu.notes?.length > 0 ? (
                                  <span className="text-sm tracking-tight italic text-gray-900 leading-tight">
                                    "{menu.notes}"
                                  </span>
                                ) : (
                                  ""
                                )}
                              </p>
                              <div className="flex">
                                <p className="w-6 text-center text-sm leading-tight">
                                  ({menu.Product?.ProductCategory?.name})
                                </p>
                                <p className="w-6 text-right text-sm leading-tight">
                                  {menu.qty}
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>

                        <div className="h-full flex flex-col justify-end items-end">
                          <div className="flex flex-col justify-between h-fit gap-8">
                            <p className="w-full text-right text-sm">
                              Penerima,
                            </p>
                            <p className="flex flex-row justify-between text-[10px] text-gray-300 tracking-tighter whitespace-nowrap">
                              Nama dan Tanda Tangan
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-red-500 italic text-sm font-light">
                      note: Mohon segera simpan di kulkas untuk yang tidak
                      segera dimakan
                    </div>
                  </div>
                  <div className="w-40 h-full px-2 flex flex-col justify-end items-end">
                    <img
                      className="h-full pl-2 object-contain object-bottom"
                      src={LogoSL}
                      alt="logo SL Kitchen"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="fixed bottom-2 left-0 sm:left-auto right-0 sm:right-4 sm:bottom-4 sm:w-auto z-40 flex justify-end border-t border-gray-100 bg-white sm:bg-transparent p-4 sm:p-0 dark:border-gray-700 dark:bg-gray-800 print:hidden sm:absolute sm:rounded-b-lg sm:border-0 ">
          <Button onClick={handlePrint} type="submit">
            Print Surat Jalan
          </Button>
        </div>
      </ModalMolecule>
    );
}

export default CreateSingleDeliverySlipModal;
