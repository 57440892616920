import { useApplicationModals } from "app/common/hooks";
import { useEffect, useState } from "react";
import { ModalMolecule } from "app/components/molecules/modal";
import { useFetcher } from "app/integration/common/hooks";
import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Button } from "app/components/atoms/button";
import { InputMolecule } from "app/components/molecules/form";

function CreateMenuStickerModal({ onSuccess = () => {} }) {
  const [queryParams, setQueryParams] = useState({
    delivery_slip: 1,
    order_ids: "",
  });

  const [report] = useFetcher({
    modelName: "report",
    methodName: "show",
    payload: { id: queryParams },
  });

  const { showStates, getPayloads, closeModal } = useApplicationModals();
  const payload = getPayloads("menuSticker.create");

  const handleClose = () => closeModal("menuSticker.create");
  const handleSuccess = () => {
    closeModal("menuSticker.create");
    onSuccess();
  };

  const [skipStiker, setSkipStiker] = useState(0);
  const handleChange = (event) => {
    setSkipStiker(Number(event.target.value));
  };

  function printEmptyStickers() {
    var emptyStickers = [];
    var idx = 0;
    while (idx < skipStiker) {
      emptyStickers.push(
        <div className="h-[90px] w-[140px] rounded-md border print:border-none p-1 flex flex-col justify-between gap-[1px] overflow-clip"></div>
      );
      idx += 1;
    }
    // console.log("empty stickers: ", emptyStickers);
    return emptyStickers;
  }

  useEffect(() => {
    if (payload?.data?.length > 0) {
      // console.log("payload: ", payload.data);
      const order_ids = payload.data.map((item) => {
        return item.order_id;
      });

      setQueryParams({
        ...queryParams,
        order_ids: order_ids.join(","),
      });
    } else {
      console.log("payload kosong");
    }
  }, [payload]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  function getTotalProductQty(order) {
    var qty = 0;
    order.listProduct.map((product, index) => {
      qty += Number(product.qty);
      return qty;
    });
    // console.log(qty);
    return qty;
  }

  var totalIdx = 0;

  return (
    <ModalMolecule
      show={showStates["menuSticker.create"]}
      title="Print Menu Sticker"
      onClose={handleClose}
      size="xl"
    >
      {/* {console.log("Report data: ", report)} */}
      <InputMolecule
        label="Skip Stiker"
        type="number"
        name={`skipStiker`}
        onChange={handleChange}
        value={skipStiker}
      />
      <div
        ref={componentRef}
        className="flex flex-wrap gap-x-[6px] gap-y-1 justify-left font-light py-2 px-1 text-xs w-[793px] h-fit border print:border-none"
      >
        {printEmptyStickers()}

        {report?.length > 0 &&
          report?.map((order, index) => {
            var curIdx = 0;
            return order.listProduct.map((product, index) => {
              var totalQty = getTotalProductQty(order);
              var idx = 0;
              var el = [];
              while (idx < product.qty) {
                // console.log("variant length: %i", product.variants?.length);
                // console.log("note length: %i", product.note?.length);
                // hanya print stiker yang pakai request khusus
                if (product.variants?.length > 0 || product.note?.length > 0) {
                  // 30 stiker per halaman, mesti kasi page break setiap 30 stiker, mulai dari stiker ke 31
                  if (
                    (Number(skipStiker) + totalIdx + 1) % 30 === 1 &&
                    Number(skipStiker) + totalIdx + 1 > 30
                  ) {
                    el.push(
                      <div className="col-span-5 h-[555px] w-full"></div>
                    );
                  }

                  el.push(
                    <div className="h-[90px] w-[140px] rounded-md border print:border-none p-1 flex flex-col justify-between gap-[1px] overflow-clip">
                      <div className="">
                        <div className="flex justify-between gap-2">
                          <p className="line-clamp-1 whitespace-nowrap overflow-ellipsis text-[10px] tracking-tighter">
                            {order.nickName?.length > 0
                              ? order.nickName
                              : order.customerName}
                          </p>
                          <p className="text-[10px] text-gray-900 font-light tracking-tighter">
                            {order.order_code}
                          </p>
                        </div>
                        <p className="text-xs tracking-tighter leading-tight line-clamp-3">
                          {product.menu}: {product.product}
                          {" - "}
                          {product.variants.join(", ")}
                        </p>
                      </div>
                      <div className="flex justify-between text-xs gap-1 items-end">
                        <p className="tracking-tight leading-tight line-clamp-2 text-[10px]">
                          Note: {product.note}
                        </p>
                        <div
                          className={
                            order.deliveryTime === 0
                              ? "hidden bg-primary-600 rounded-full px-1 text-white h-fit text-[10px]"
                              : "hidden"
                          }
                        >
                          {idx + curIdx + 1}/{totalQty}
                        </div>
                      </div>
                    </div>
                  );
                  totalIdx += 1;
                }
                idx += 1;
              }
              curIdx += product.qty;
              // console.log("el: ", el);
              return el;
            });
          })}
      </div>
      <div className="fixed bottom-2 left-0 sm:left-auto right-0 sm:right-4 sm:bottom-4 sm:w-auto z-40 flex justify-end border-t border-gray-100 bg-white sm:bg-transparent p-4 sm:p-0 dark:border-gray-700 dark:bg-gray-800 print:hidden sm:absolute sm:rounded-b-lg sm:border-0 ">
        <Button onClick={handlePrint} type="submit">
          Print Stiker Menu
        </Button>
      </div>
    </ModalMolecule>
  );
}

export default CreateMenuStickerModal;
