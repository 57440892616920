import clsx from 'clsx';
import style from 'style/height.module.css';

function TableWrapper({ children, asIndex = true, enableScroll = true }) {
  return (
    <div
      className={clsx(
        "print:h-full print:overflow-hidden",
        asIndex ? clsx(style["table-as-index-height"], "") : "",
        enableScroll ? "overflow-y-auto" : ""
      )}
    >
      <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700 break-inside-auto">
        {children}
      </table>
    </div>
  );
}

export default TableWrapper;
